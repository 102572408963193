:root {
    --primary-color: #624AF5;
    --secondary-color: #A433F6;
    --white: #ffffff;
  }
.bodyContainer{
    width:100%;
    transition: .2s all;
}
.close-modal-button{
    font-size: 1.5em;
    background-color: transparent;
    border: none;
}
.Toastify{
    z-index: 999999999;
}
.swal2-container {
    z-index: 2671 !important;
  }
.react-bootstrap-drawer{
    top:1px;
    height: 100vh;
}
.react-bootstrap-drawer-toc-header{
    margin-top: 0 !important;
    padding: 0 !important;
}
.nav-item{
    border-bottom: 1px solid #d9d9d9;
    /* margin-bottom: 10px; */
    padding-bottom: 5px;
    padding-top: 5px;
}
.nav-item:hover{
    background-color: #d9d9d9;
}
.toggled + .bodyContainer{
    width:calc(100% - 80px)
}
.bg-primary.accounts-links {
    background-color: var(--primary-color) !important;
}
.btn-primary {
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
}
.btn-primary:hover {
    background-color: #0bb2d4;
    border-color: #0bb2d4;
}
.MuiAppBar-colorPrimary {
    background-color: var(--primary-color) !important;
}
.MuiTableBody-root a{
    color: var(--primary-color);
    font-weight:600;
    text-decoration: none;
}
.MuiTableBody-root a:hover{
    text-decoration: underline;
}
.MuiAutocomplete-popper{
    z-index: 2236 !important;
}

/*Sidebar*/
.lalqgO .head-div .icon-suffix {
    margin: 0 !important;
}
.accounts-links .nav-link{
    padding: 24px 1rem;
}